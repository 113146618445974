<template>
  <v-navigation-drawer
      v-if="instance"
      app
      right
      temporary
      v-model="active"
      :overlay-opacity="0.25"
      :width="$vuetify.breakpoint.smAndDown ? '90%' : '42%' "
  >
    <v-layout fill-height column class="pt-3 pb-5">
      <div style="position: fixed; right: 16px; top: 16px;">
        <v-btn @click="active = false" fab small depressed color="secondary lighten-5">
          <v-icon color="secondary">mdi-close</v-icon>
        </v-btn>
      </div>
      <slot></slot>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "Sidebar",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    instance: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    active: {
      get() {
        return this.instance !== null;
      },
      set(v) {
        if (v === false) this.$emit('close');
      }
    }
  },

  mounted: function () {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = 'hidden';
  },
  beforeDestroy: function () {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = null;
  },
}
</script>